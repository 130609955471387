import { addTracking } from './tracking';
import { loadUis } from './uiLoading';

void loadUis();

void addTracking();

if (module.hot) {
  module.hot.accept();
}
