import { Extras, Hub as SentryHub } from '@sentry/types';
import { FEATURE_DEFINITIONS, getEnsignFeatures, UiHubFeatures } from './ensign';
import {
  DecoratedUIGonEntry,
  InitialUIGonEntry,
  isInitialUiGonEntry,
  UIBootstrapFunction
} from './module/uiLoading';

/*
  This module is the "ui-hub loading script" shown in this diagram:
  https://user-images.githubusercontent.com/1171203/161653137-bbc3ac9b-bbd5-4488-8b21-6a8c48d504fa.png
*/

/*
  Performance tracking

  {
    "name": "ensign-ui",
    "event": "bundleRequested" | "bundleLoaded" | "initializationStarted" | "initializationFinished" | "interactive",
    "ts": 1579124683853
  }

  |--------------------------------------------interactive-------------------------------------------------|
  |--------------------------------------------initialized-------------------------------|
  |------------download---------------|  |---------------initialization------------------|

  [bundleRequested]      [bundleLoaded]
                                        [initializationStarted]  [initializationFinished]
                                                                                            ["interactive"]
*/

type UnbounceUITimingEvent = {
  name: string;
  event: UILoadingEventName;
  ts: number;
};

enum UILoadingEventName {
  BundleRequested = 'bundleRequested',
  BundleLoaded = 'bundleLoaded',
  InitializationStarted = 'initializationStarted',
  InitializationFinished = 'initializationFinished',
  Interactive = 'interactive'
}

type UIEventTimes = {
  [uiName: string]: {
    [eventName in UILoadingEventName]: number;
  };
};

const INTERACTIVE_THRESHOLD_MS = 5 * 1000;
const TIMEOUT_MS = 15 * 1000;
const METRICS_ENDPOINT = '/metrics'; // Webapp endpoint which receives UI load timings
const getCsrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content') || '';
let ensignFeatures: UiHubFeatures | undefined;

const log = (message?: any, ...optionalParams: any[]) => {
  const loggingEnabled = ensignFeatures
    ? ensignFeatures['uiLoading/logging']
    : FEATURE_DEFINITIONS['uiLoading/logging'].default;

  if (!loggingEnabled) return;

  const firstArgs = typeof message === 'string' ? [`💿 ${message}`] : ['💿', message];

  console.log(...firstArgs, ...optionalParams);
};

const isUnbounceUITimingEvent = (event?: any): event is CustomEvent<UnbounceUITimingEvent> => {
  if (
    typeof event?.detail?.name === 'string' &&
    typeof event?.detail?.event === 'string' &&
    typeof event?.detail?.ts === 'number'
  ) {
    return true;
  } else {
    console.error('Invalid UnbounceUITimingEvent', event);
    return false;
  }
};

const emitTimingEvent = (uiName: string, eventName: UILoadingEventName) => {
  const event = new CustomEvent('UnbounceUITimingEvent', {
    detail: {
      name: uiName,
      event: eventName,
      ts: new Date().getTime()
    }
  });

  window.dispatchEvent(event);
};

const getTiming = (metricName: string, uiName: string, ms: number) => ({
  metric: 'ui.loading.' + metricName,
  ms: ms,
  tags: ['ui:' + uiName]
});

const isSentryHub = (thing?: any): thing is SentryHub =>
  Boolean(thing) &&
  typeof thing.withScope === 'function' &&
  typeof thing.captureMessage === 'function';

const notifySentry = (message: string, extras: Extras) => {
  const hub = (window as any).ubSentryHubs?.['lp-webapp'];

  if (isSentryHub(hub)) {
    log('Notifying Sentry:', message, extras);
    hub.withScope(scope => {
      scope.setExtras(extras);
      hub.captureMessage(message);
    });
  }
};

const downloadUiBundle = (ui: InitialUIGonEntry) => {
  const script = document.createElement('script');
  const scriptLoadPromise = new Promise<InitialUIGonEntry>(resolve => {
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(ui);
    document.head.appendChild(script);
    script.src = ui.js;

    log('Downloading bundle', ui.name);
    emitTimingEvent(ui.name, UILoadingEventName.BundleRequested);
  });

  if (ui.css && typeof ui.css === 'string' && ui.css !== 'data:text/css') {
    const css = document.createElement('link');

    css.rel = 'stylesheet';
    css.type = 'text/css';
    css.href = ui.css;
    document.head.appendChild(css);
  }

  void scriptLoadPromise.then(onBundleLoaded);

  return scriptLoadPromise;
};

const onBundleLoaded = (ui: InitialUIGonEntry) => {
  log('Downloaded bundle', ui.name);
  emitTimingEvent(ui.name, UILoadingEventName.BundleLoaded);

  // Capture window[ui.name] now as it can be ovewritten if a DOM element's ID is set to ui.name:
  // https://html.spec.whatwg.org/multipage/window-object.html#named-access-on-the-window-object
  const bootstrapFn = (window as any)[ui.name]; // UI bundles should define this
  const decoratedUI = ui as DecoratedUIGonEntry;

  // preloadedState allows a parent UI to pass some state to a child UI
  decoratedUI.initialize = async (preloadedState?: any) => {
    if (typeof bootstrapFn !== 'function') {
      console.error('Missing UI init function: window["' + ui.name + '"]', bootstrapFn);
      return;
    }

    log('Calling bootstrap function', ui.name);
    emitTimingEvent(ui.name, UILoadingEventName.InitializationStarted);

    const initResult = (bootstrapFn as UIBootstrapFunction)(
      ui.context,
      ui.containers,
      ui.env,
      preloadedState
    );
    const initPromise = initResult instanceof Promise ? initResult : Promise.resolve();

    await initPromise;

    log('Bootstrap function completed', ui.name);
    emitTimingEvent(ui.name, UILoadingEventName.InitializationFinished);
  };

  if (decoratedUI.deferInitialize) return;

  if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', () => {
      if (typeof decoratedUI.initialize === 'function') {
        void decoratedUI.initialize();
      }
    });
    log('Preparing to initialize on DOMContentLoaded', ui.name);
  } else {
    void decoratedUI.initialize();
  }
};

const nonBlockingEnsignFetch = () => {
  void getEnsignFeatures().then(result => {
    ensignFeatures = result;
    return ensignFeatures;
  });
};

export const loadUis = () => {
  const uiGonEntries = (window as any).gon?.javascript_uis;
  // gon.javascript_uis defined by https://github.com/unbounce/lp-webapp/blob/master/app/controllers/concerns/javascript_loading.rb

  const uiEvents: UIEventTimes = {};
  const uiTimeouts: { [uiName: string]: NodeJS.Timeout } = {};
  let timings: ReturnType<typeof getTiming>[] = [];

  if (typeof uiGonEntries !== 'object' || !Object.keys(uiGonEntries).length) return;

  log('window.gon.javascript_uis', uiGonEntries);

  nonBlockingEnsignFetch(); // Side effect for logging

  window.addEventListener('UnbounceUITimingEvent', (event: Event) => {
    if (!isUnbounceUITimingEvent(event)) return;

    const name = event.detail.name;
    const events = (uiEvents[name] = uiEvents[name] || {});
    const uiGonEntry = uiGonEntries[name];

    if (events[event.detail.event]) {
      // Already seen this type of event for this UI
      return;
    }

    events[event.detail.event] = event.detail.ts;

    switch (event.detail.event) {
      case UILoadingEventName.BundleRequested:
        if (isInitialUiGonEntry(uiGonEntry) && !uiGonEntry.deferInitialize) {
          // Avoid setting timeout if this UI's initialization should be deferred

          uiTimeouts[name] = setTimeout(() => {
            log('⚠️ UI never became interactive', name);
            notifySentry('UI never became interactive', {
              name,
              timeout: TIMEOUT_MS
            });
          }, TIMEOUT_MS);
        }
        break;
      case UILoadingEventName.BundleLoaded:
        if (events.bundleRequested) {
          const ms = events.bundleLoaded - events.bundleRequested;
          timings.push(getTiming('download', name, ms));
        }
        break;
      case UILoadingEventName.InitializationStarted:
        break;
      case UILoadingEventName.InitializationFinished:
        if (events.initializationStarted) {
          const ms = events.initializationFinished - events.initializationStarted;
          timings.push(getTiming('initialization', name, ms));
        }

        if (events.bundleRequested) {
          const ms = events.initializationFinished - events.bundleRequested;
          timings.push(getTiming('initialized', name, ms));
        }
        break;
      case UILoadingEventName.Interactive:
        if (uiTimeouts[name]) {
          clearTimeout(uiTimeouts[name]);
          delete uiTimeouts[name];
        }

        if (events.bundleRequested) {
          const ms = events.interactive - events.bundleRequested;
          timings.push(getTiming('interactive', name, ms));

          if (ms > INTERACTIVE_THRESHOLD_MS) {
            log(`⚠️ UI did not become interactive within threshold`, name);
            notifySentry('UI did not become interactive within threshold', {
              name,
              threshold: INTERACTIVE_THRESHOLD_MS,
              events
            });
          }

          log(`${name} became interactive. Total load time ms:`, ms);
        }
        break;
      default:
        console.error('Unknown UnbounceUITimingEvent', event);
    }
  });

  // Send batches of event timings to lp-webapp for perf monitoring
  setInterval(() => {
    if (!timings.length) return;

    const body = JSON.stringify({ timings: timings });

    log(`Sending UI timings to ${METRICS_ENDPOINT}`, timings);
    void fetch('/metrics', {
      // TODO use beacon and feature-flag /metrics endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken
      },
      body: body
    });

    timings = [];
  }, 1000);

  Object.values(uiGonEntries).forEach(uiGonEntry => {
    if (!isInitialUiGonEntry(uiGonEntry)) return;

    const decoratedUIEntry = uiGonEntry as DecoratedUIGonEntry;

    decoratedUIEntry.initialize = 'BUNDLE NOT LOADED';

    if (uiGonEntry.deferDownload) {
      decoratedUIEntry.download = () => downloadUiBundle(uiGonEntry);
    } else {
      const downloadPromise = downloadUiBundle(uiGonEntry);

      decoratedUIEntry.download = () => downloadPromise;
    }
  });
};
