import { Context } from '@unbounce/ensign';
import { Environment } from '@unbounce/ensign/build/types';

export type InitialUIGonEntry = {
  name: string;
  env: Environment;
  js: string;
  css: string;
  containers: {
    [key: string]: string;
  };
  context: Context;
  deferDownload?: boolean;
  deferInitialize?: boolean;
};

export type DecoratedUIGonEntry = InitialUIGonEntry & {
  download: () => Promise<any>;
  initialize: ((preloadedState?: any) => Promise<void>) | string;
};

export type UIBootstrapFunction = (
  context: InitialUIGonEntry['context'],
  containers: InitialUIGonEntry['containers'],
  env: InitialUIGonEntry['env'],
  preloadedState?: any
) => Promise<any> | any;

export const isInitialUiGonEntry = (thing?: any): thing is InitialUIGonEntry =>
  typeof thing === 'object' && typeof thing.name === 'string' && typeof thing.js === 'string';

export const isDecoratedUiGonEntry = (thing?: any): thing is DecoratedUIGonEntry =>
  isInitialUiGonEntry(thing) &&
  'initialize' in thing &&
  typeof (thing as DecoratedUIGonEntry).download === 'function';
