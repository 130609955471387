import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { getEnsignFeatures } from './ensign';
import { account_uuid, client_uuid, user_uuid } from './gon';
import { EventNameForUIs, TrackEventName, UITrackingEvent } from './module/tracking';

export const addTracking = async () => {
  const ensignFeatures = await getEnsignFeatures();
  const enabled = ensignFeatures['tracking/enabled'];
  const endpoint = ensignFeatures['tracking/endpoint'];

  if (!enabled || !endpoint) {
    return;
  }

  const sendEvent = getEventSender(endpoint);

  sendEvent({ event_type: 'PAGE_VIEW' });

  window.addEventListener(EventNameForUIs, ((event: CustomEvent) => {
    const sendableEvent = unpackCustomDetail(event.detail);

    if (sendableEvent) {
      sendEvent(sendableEvent);
    }
  }) as EventListener);

  window.addEventListener(TrackEventName, ((event: CustomEvent) => {
    // todo: https://unbounce.atlassian.net/browse/BD-9582
    // send to mixpanel & event bus
    console.info('Track event:', event.detail);
  }) as EventListener);
};

export const unpackCustomDetail = (thing: any) => {
  if (thing && typeof thing === 'object') {
    const { event_type, event_data: rawDetails, workflow: rawWorkflow } = thing;

    if (typeof event_type === 'string' && event_type) {
      let event_data = undefined;
      let workflow = '';

      if (typeof rawDetails === 'object') {
        try {
          event_data = JSON.parse(JSON.stringify(rawDetails));
        } catch (ex) {
          console.warn(`Bad event_data for ${event_type} tracking event`, rawDetails);
        }
      }

      if (typeof rawWorkflow === 'string') {
        workflow = rawWorkflow;
      } else if (
        Array.isArray(rawWorkflow) &&
        rawWorkflow.every(item => typeof item === 'string')
      ) {
        workflow = rawWorkflow.join(','); // 1.1.0 had workflow as string[]
      }

      const sendableEvent: UITrackingEvent = {
        event_type,
        event_data,
        workflow
      };

      return sendableEvent;
    } else {
      console.warn(`Bad tracking event event_type`, event_type);
    }
  }

  return null;
};

const getEventSender = (endpoint: string) => (event: UITrackingEvent) => {
  const postBody = buildEventDataJSON(event);

  if (postBody) {
    const headers = { type: 'application/json' };
    const blob = new Blob([postBody], headers);

    navigator.sendBeacon(endpoint, blob);
  }
};

// https://unbounce.atlassian.net/wiki/spaces/DATA/pages/1400242388/Webapp+UI+Events+API+and+Schemas
export const buildEventDataJSON = ({ event_type, event_data, workflow }: UITrackingEvent) => {
  try {
    const defaultUuid = getDefaultUuid();
    const userIsAnonymous = !user_uuid;
    const eventDataWithAnonymous =
      event_data || userIsAnonymous
        ? {
            ...event_data,
            ...(userIsAnonymous ? { user_is_anonymous: true } : {})
          }
        : null;

    const data = {
      event_type,
      event_uuid: uuidv4(),
      page_url: window.location.href,
      client_timestamp: Date.now(),
      account_uuid: account_uuid || defaultUuid,
      client_uuid: client_uuid || defaultUuid,
      user_uuid: user_uuid || defaultUuid,
      event_data: JSON.stringify(eventDataWithAnonymous),
      workflow: workflow || null
    };

    return JSON.stringify(data);
  } catch (ex) {
    console.warn(`Bad details for ${event_type} tracking event`, event_data);

    return null;
  }
};

export const getDatestamp = (date: Date) => date.toISOString().substring(0, 7);
const DATE = new Date();
const DEFAULT_UUIDS: { [datestamp: string]: string } = {};
export const getDefaultUuid = (date: Date = DATE) => {
  const datestamp = getDatestamp(date);

  if (DEFAULT_UUIDS[datestamp]) return DEFAULT_UUIDS[datestamp];

  const NAMESPACE_UUID = 'a436b0ec-cc88-4060-b601-0e1fa158ef5e'; // Constant value so we always generate the same DEFAULT_UUID for the same datestamp
  const uuid = uuidv5(datestamp, NAMESPACE_UUID);

  DEFAULT_UUIDS[datestamp] = uuid;

  return uuid;
};
